<!-- eslint-disable no-irregular-whitespace -->
<template>
  <section class="ds s-pt-50 s-pb-20 s-pt-xl-60 s-pb-xl-40 c-gutter-60 c-mb-30">
    <div class="container mt-1">
      <div class="col-12" v-if="category == 'tarot-shuffle'">
        <div
          class="vertical-item text-center service-layout-1 box-shadow content-padding"
        >
          <div class="item-media">
            <img
              src="@/images/category/tarot-shuffle.jpg"
              alt="img"
              @click="$router.push({ name: 'Tarot Shuffle Form' })"
            />
          </div>
          <div class="item-content" id="content">
            <p class="mt-0 links-maincolor2" id="head">
              တစ်နေ့တာ တားရော့ကံကြမ္မာ
            </p>
            <p id="desc">တစ်နေ့တာ တားရော့ကံကြမ္မာအတွက် ၂၀၀၀ ကျပ်ကျသင့်ပါမည်။</p>

            <span
              id="btn-oneyear"
              @click="$router.push({ name: 'Tarot Shuffle Form' })"
            >
              မေးမည်
            </span>
          </div>
        </div>
      </div>

      <!-- col- -->

      <div class="col-12" v-if="category == 'compatability-love'">
        <div
          class="vertical-item text-center service-layout-1 box-shadow content-padding"
        >
          <div class="item-media">
            <img
              src="@/images/category/compatability-love.jpg"
              alt="img"
              @click="$router.push({ name: 'Compatability Love Form' })"
            />
          </div>
          <div class="item-content" id="content">
            <p class="mt-0 links-maincolor2" id="head">ဖူးစာဖက်</p>
            <p id="desc">
              သင်နှင့် သင့်ချစ်သူ၏ဇာတာခွင် တိုက်စစ်ကြည့်ရအောင်။ ၂၀၀၀
              ကျပ်ကျသင့်ပါမည်။
            </p>

            <span
              id="btn-oneyear"
              @click="$router.push({ name: 'Compatability Love Form' })"
            >
              မေးမည်
            </span>
          </div>
        </div>
      </div>

      <!-- col  -->

      <div class="col-12" v-if="category == 'terms_and_conditions'">
        <div class="tnc">
          <div class="scroll-text">
            <KbzMM v-if="isMM"/>
            <KbzEng v-else/>
          </div>
        </div>
      </div>

      <div class="col-12" v-if="category == 'frequently_ask_question'">
        <div class="tnc">
          <div class="scroll-text">
            <h6 class="my-2">{{ isMM ? "မေးလေ့ရှိသော မေးခွန်းများ" : "Frequently Asked Questions" }}</h6>
            <ol v-if="isMM">
              <li>
                <b>မေး -</b> KBZPay ရဲ့ ၁၈၇၅ ဗေဒင် မီနီအက်စ်မှာ ဗေဒင် ဘယ်လိုမေးမြန်းလို့ရနိုင်မလဲ။
                <br/>
                <b>ဖြေ -</b> ပထမဦးစွာ ၁၈၇၅ ဗေဒင် မီနီအက်စ် ပင်မစာမျက်နှာမှ မေးမြန်းလိုသည့်ကဏ္ဍအား ရွေးချယ်ပြီး မိမိကြိုက်နှစ်သက်ရာ ကဏ္ဍအလိုက်ရွေးချယ်၍ မေးမြန်းနိုင်ပါသည်။
              </li>
              <li>
                <b>မေး -</b> အချစ်ရေး အိမ်ထောင်ရေး ကျန်းမာရေး နှင့်ပက်သက်ပြီး ဘယ်လိုမေးမြန်းလို့ရနိုင်ပါသလဲ။
                <br/>
                <b>ဖြေ -</b> ၁၈၇၅ ဗေဒင် KBZPay in app တွင် ကဏ္ဍအလိုက်ရွေးချယ်မေးမြန်းလို့ရနိုင်ပါသည်။
              </li>
              <li>
                <b>မေး -</b> ဟောစာတမ်းအဖြေကို ဘယ်လိုရနိုင်မလဲ။
                <br/>
                <b>ဖြေ -</b> မေးခွန်းပေးပို့မေးမြန်းပြီးပါက ၁၈၇၅ ဗေဒင်ရှိ ဗေဒင်ဆရာများမှ ဟောစာတမ်းစာဖိုင် PDF ကို (၃) ရက်အတွင်း "အဖြေများ" နေရာနှင့် ဗေဒင်မေးမြန်းသူ၏ viberတွင် ဟောစာတမ်းအဖြေကိုပေးပို့ထားပါမည်။ ဝန်ဆောင်မှုရယူထားသော ဟောစာတမ်းများ၏ အဖြေများကို ဝင်ရောက်နားထောင် ဖတ်ရှုနိုင်ပါသည်။
              </li>
              <li>
                <b>မေး -</b> ဆရာနှင့်တိုက်ရိုက်မေးချင်ရင် ဘယ်လိုဆက်သွယ်ရမလဲ။
                <br/>
                <b>ဖြေ -</b> ဖုန်းဖြင့်တိုက်ရိုက်ဗေဒင်မေးမည့် ဝန်ဆောင်မှုကိုရယူ၍ တနင်္လာနေ့မှ စနေထိ ဖုန်းပြန်လည်ခေါ်ပေးရန် ဖောင်ဖြည့်ပီ မနက် (၉) နာရီခွဲမှ ညနေ (၅) နာရီအတွင်း ဆက်သွယ်ဖြေကြားပေးပါသည်။ 1875 Bay Din Team ၏ဖုန်းနံပါတ် 09-456880335 မှတစ်ဆင့်လည်း ဆက်သွယ်မေးမြန်းနိုင်ပါသည်။
              </li>
              <li>
                <b>မေး -</b> ဗေဒင်မေးလျှင် ဘယ်လောက်ကုန်ကျပါသလဲ။
                <br/>
                <b>ဖြေ -</b> နေ့စဉ် တားရော့ကဒ်ဆွဲလျှင် ၂,၀၀၀ ကျပ်၊ ရာသီခွင် ဖူးစာဖက်တိုက် လျှင် ၂,၀၀၀ ကျပ်၊ တားရော့ ဝန်ဆောင်မှု မေးခွန်းနှစ်ခုလျှင် ၅,၀၀၀ ကျပ်၊ လေးခုမေးလျှင် ၇,၀၀၀ ကျပ်၊တစ်နှစ်စာဟောစာတမ်းမေးလျှင် ၅,၀၀၀ ကျပ်၊ အကြားအမြင်ဟောစာတမ်းမေးလျှင် ၁၂,၀၀၀ ကျပ်၊ လက္ခဏာဗေဒင်မေးလျှင် ၁၀,၀၀၀ ကျပ်၊ ကလေးအမည်ပေးမေးလျှင် ၇,၀၀၀ ကျပ်၊ ဖူးစာဖက်အချစ်ရေးမေးလျှင် ၇,၀၀၀ ကျပ်၊ ဖုန်းဖြင့်တိုက်ရိုက်မေးမည်မေးလျှင် ၇,၀၀၀ ကျပ် ကျသင့်မှာ ဖြစ်ပါသည်။
              </li>
              <li>
                <b>မေး -</b> အဆင်မပြေမှုများအတွက် ဘယ်လိုဆက်သွယ်ရမလဲ။
                <br/>
                <b>ဖြေ -</b> 1875 ဗေဒင်၏ Hotline ဖုန်းနံပါတ် ​၀၉၄၅၆၈၈၀၃၃၅ သို့ နေ့စဉ် မနက် (၉) နာရီခွဲမှ ညနေ (၅) နာရီအတွင်း ဆက်သွယ်လို့ရပါသည်။
              </li>
            </ol>

            <ol v-else>
              <li>
                <b>Question:</b> How can I consult astrology in KBZPay's 1875 Astrology Mini App?
                <br/>
                <b>Answer:</b> First, select the desired category from the main page of the 1875 Astrology Mini App, and then you can ask questions according to your preferred category.
              </li>
              <li>
                <b>Question:</b> How can I ask questions related to love, marriage, and health?
                <br/>
                <b>Answer:</b> In the KBZPay app, you can ask questions by selecting specific categories according to the 1875 Astrology Mini App.
              </li>
              <li>
                <b>Question:</b> How can I get the answer to the horoscope prediction?
                <br/>
                <b>Answer:</b> After submitting your question, the astrologers from the 1875 astrology system will send the horoscope prediction in both audio (MP3) and PDF formats within (3) days. The answers will be delivered to the "Answers" section in the app and to the Viber account of the person who asked the question. You can access and listen to or read the answers for the horoscope predictions you have requested.
              </li>
              <li>
                <b>Question:</b> How can I contact the astrologer directly if I want to ask questions in person?
                <br/>
                <b>Answer:</b> To directly consult with an astrologer over the phone, you can fill out a form to request a callback service. The astrologers are available to answer calls from Monday to Saturday, between 9:30 AM and 5:00 PM. You can also contact 1875 Bay Din Team via their phone number, 09-456880335, to ask questions and seek guidance.
              </li>
              <li>
                <b>Question:</b> How much does it cost to ask a question to the astrologer?
                <br/>
                <b>Answer:</b>
                  <ul>
                    <li>Daily Tarot Shuffle reading: 2,000 MMK.</li>
                    <li>Zodiac compatibility reading: 2,000 MMK.</li>
                    <li>Tarot service (2) questions: 5,000 MMK. (4) Questions: 7,000 MMK.</li>
                    <li>One Year Horo Prediction: 5,000 MMK.</li>
                    <li>Clairvoyance reading: 12,000 MMK.</li>
                    <li>Palmistry reading: 10,000 MMK.</li>
                    <li>Baby naming consultation: 7,000 MMK.</li>
                    <li>Love Horo Prediction: 7,000 MMK.</li>
                    <li>Direct phone consultation: 7,000 MMK.</li>
                  </ul>
              </li>
              <li>
                <b>Question:</b> How can I contact for issues or problems?
                <br/>
                <b>Answer:</b> For 1875 Astrology Mini App, you can contact the hotline number 09456880335 daily between 9:30 AM and 5:00 PM.
              </li>
            </ol>
          </div>
        </div>
      </div>

      <!-- col- -->

      <div class="row">
        <!-- <div class="col-12" v-if="category == 'oneyear'">
          <div
            class="vertical-item text-center service-layout-1 box-shadow content-padding"
          >
            <div class="item-media">
              <img
                src="@/images/category/oneyear.png"
                alt="img"
                @click="
                  $router.push({ name: 'special', params: { categoryId: 10 } })
                "
              />
            </div>
            <div class="item-content" id="content">
              <p class="mt-0 links-maincolor2" id="head">တစ်နှစ်စာဟောစာတမ်း</p>
              <p id="desc">
                တစ်နှစ်စာအထူးဟောစာတမ်းအတွက် ဉာဏ်ပူဇော်ခ ၄၅၀၀ ကျပ်ကျသင့်ပါမည်။
              </p>

              <span
                id="btn-oneyear"
                @click="
                  $router.push({ name: 'special', params: { categoryId: 10 } })
                "
              >
                မေးမည်
              </span>
            </div>
          </div>
        </div> -->

        <!-- col- -->
        <div class="col-12" v-if="category == 'tarot'">
          <div
            class="vertical-item text-center service-layout-1 box-shadow content-padding"
          >
            <div class="item-media">
              <img
                src="@/images/category/tarot.jpg"
                alt="img"
                @click="
                  $router.push({
                    name: 'tarot',
                    params: { categoryName: 'tarot' },
                  })
                "
              />
            </div>
            <div class="item-content" id="content">
              <p class="mt-0 links-maincolor2" id="head">တားရော့ဗေဒင်</p>
              <p id="desc">
                တားရော့ဗေဒင်အတွက် ဉာဏ်ပူဇော်ခ မေးခွန်း(၂) ခုလျှင် ၅၀၀၀ ကျပ်,
                မေးခွန်း (၄)ခုလျှင် ၇၀၀၀ ကျပ်ကျသင့်ပါမည်။
              </p>

              <span
                id="btn-oneyear"
                @click="
                  $router.push({
                    name: 'tarot',
                    params: { categoryName: 'tarot' },
                  })
                "
              >
                မေးမည်
              </span>
            </div>
          </div>
        </div>

        <!-- .col-* -->
        <div class="col-12" v-if="category == 'clairvoyance'">
          <div
            class="vertical-item text-center service-layout-1 box-shadow content-padding"
          >
            <div class="item-media">
              <img
                src="@/images/category/clairvoyance.png"
                alt="img"
                @click="
                  $router.push({
                    name: 'star',
                    params: { categoryName: 'clairvoyance' },
                  })
                "
              />
            </div>
            <div class="item-content" id="content">
              <p class="mt-0 links-maincolor2" id="head">အကြားအမြင်ဟောစာတမ်း</p>
              <p id="desc">
                အကြားအမြင်အဟော။ အချစ်ရေး၊အိမ်ထောင်ရေး၊စီးပွားရေး၊အလုပ်အကိုင်၊
                ငွေရေးကြေးရေး၊ကျန်းမာရေး၊လူမှုရေး အဟောများကို
                မေးမြန်းနိုင်ပါသည်။ ဥာဏ်ပူဇော်ခ ၁၂၀၀၀ကျပ် ကျသင့်ပါမည်။
              </p>
              <span
                id="btn-clairvoyance"
                @click="
                  $router.push({
                    name: 'clairvoyance',
                    params: { categoryName: 'clairvoyance' },
                  })
                "
              >
                မေးမည်
              </span>
            </div>
          </div>
        </div>
        <!-- .col-* -->
        <div class="col-12" v-if="category == 'free'">
          <div
            class="vertical-item text-center service-layout-1 box-shadow content-padding"
          >
            <div class="item-media">
              <img
                src="@/images/category/free.png"
                alt="img"
                @click="$router.push({ name: 'free' })"
              />
            </div>
            <div class="item-content" id="content">
              <p class="mt-0 links-maincolor2" id="head">
                အခမဲ့ (နေ့စဉ်၊အပတ်စဉ်၊လစဉ်)
              </p>
              <p id="desc">
                အချစ်ဗေဒင်၊ အကျိုးပေးဂဏန်း၊ လစဉ်အဟောများကို အခမဲ့ရယူနိုင်ပါသည်။
              </p>
              <span id="btn-free" @click="$router.push({ name: 'free' })"
                >မေးမည်</span
              >
            </div>
          </div>
        </div>
        <!-- .col-* -->
        <div class="col-12" v-if="category == 'chiromancy'">
          <div
            class="vertical-item text-center service-layout-1 box-shadow content-padding"
          >
            <div class="item-media">
              <img
                src="@/images/category/chiromancy.png"
                alt="img"
                @click="
                  $router.push({
                    name: 'star',
                    params: { categoryName: 'chiromancy' },
                  })
                "
              />
            </div>
            <div class="item-content" id="content">
              <p class="mt-0 links-maincolor2" id="head">လက္ခဏာဗေဒင်</p>
              <p id="desc">
                ဆရာရဲရန်အောင် (လက္ခဏာဗေဒပညာရှင်) ရဲ့ လက္ခဏာဗေဒင်
                မေးမြန်းမည်ဆိုပါက ဉာဏ်ပူဇော်ခ ၁၀၀၀၀ ကျပ် ကျသင့်ပါမည်။
                <br /><br />

                လူမှုရေး၊စီးပွားရေး၊အိမ်ထောင်ရေး၊အချစ်ရေး၊
                ငွေရေးကြေးရေး၊ပညာရေး၊ကျန်းမာရေး၊ထီကံများ မေးမြန်းနိုင်ပါသည်။
              </p>

              <span
                id="btn-chiromancy"
                @click="
                  $router.push({
                    name: 'star',
                    params: { categoryName: 'chiromancy' },
                  })
                "
              >
                မေးမည်
              </span>
            </div>
          </div>
        </div>
        <!-- .col-* -->
        <div class="col-12" v-if="category == 'child'">
          <div
            class="vertical-item text-center service-layout-1 box-shadow content-padding"
          >
            <div class="item-media">
              <img
                src="@/images/category/child.png"
                alt="img"
                @click="
                  $router.push({ name: 'special', params: { categoryId: 11 } })
                "
              />
            </div>
            <div class="item-content" id="content">
              <p class="mt-0 links-maincolor2" id="head">ကလေးအမည်ပေး</p>
              <p id="desc">
                ကလေးအမည်ပေးအတွက် ဉာဏ်ပူဇော်ခ ၇၀၀၀ ကျပ်ကျသင့်ပါမည်။
              </p>

              <span
                id="btn-child"
                @click="
                  $router.push({ name: 'special', params: { categoryId: 11 } })
                "
              >
                မေးမည်
              </span>
            </div>
          </div>
        </div>
        <!-- .col-* -->
        <div class="col-12" v-if="category == 'love'">
          <div
            class="vertical-item text-center service-layout-1 box-shadow content-padding"
          >
            <div class="item-media">
              <img
                src="@/images/category/love.png"
                alt="img"
                @click="
                  $router.push({ name: 'special', params: { categoryId: 275 } })
                "
              />
            </div>
            <div class="item-content" id="content">
              <p class="mt-0 links-maincolor2" id="head">အချစ်ရေးအဟော</p>
              <p id="desc">
                အချစ်ဇာတာဟောစာတမ်းအတွက် အမျိုးသားနှင့်အမျိုးသမီး၏
                အမည်၊မွေးနေ့၊မွေးသက္ကရာဇ်၊နေရပ်လိပ်စာ၊ ဆက်သွယ်ရမည့်ဖုန်းနံပါတ်
                ထည့်သွင်းရန်လိုအပ်ပါသည်။ အချစ်ဇာတာဟောစာတမ်းအတွက် ဉာဏ်ပူဇော်ခ
                ၇၀၀၀ ကျပ်ကျသင့်ပါမည်။
              </p>

              <span
                id="btn-love"
                @click="
                  $router.push({ name: 'special', params: { categoryId: 275 } })
                "
              >
                မေးမည်
              </span>
            </div>
          </div>
        </div>
        <!-- .col-* -->
        <div class="col-12" v-if="category == 'phone'">
          <div
            class="vertical-item text-center service-layout-1 box-shadow content-padding"
          >
            <div class="item-media">
              <img
                src="@/images/category/phone.png"
                alt="img"
                @click="$router.push({ name: 'phone' })"
              />
            </div>
            <div class="item-content" id="content">
              <p class="mt-0 links-maincolor2" id="head">
                ဖုန်းဖြင့်တိုက်ရိုက်ဗေဒင်မေးမည်
              </p>
              <p id="desc">
                ဗေဒင်ဆရာများဖြင့် ဖုန်းခေါ်ဆို၍ တိုက်ရိုက်မေးမြန်းလိုပါက
                ဉာဏ်ပူဇော်ခ ၇၀၀၀ ကျပ်ကျသင့်မည်ဖြစ်ပါသည်။
              </p>

              <span id="btn-phone" @click="$router.push({ name: 'phone' })">
                မေးမည်
              </span>
            </div>
          </div>
        </div>
        <!-- .col-* -->
        <div class="col-12" v-if="category == 'direct'">
          <div
            class="vertical-item text-center service-layout-1 box-shadow content-padding"
          >
            <div class="item-media">
              <img
                src="@/images/category/direct.png"
                alt="img"
                @click="$router.push({ name: 'direct' })"
              />
            </div>
            <div class="item-content" id="content">
              <p class="mt-0 links-maincolor2" id="head">
                ဗေဒင်ဆရာနှင့်တိုက်ရိုက်မေးမည်
              </p>
              <p id="desc">
                အချစ်ရေး၊အိမ်ထောင်ရေး၊ကျန်းမာရေး၊ကိုယ်ပိုင်စီးပွားရေး၊အလုပ်အကိုင်၊ပညာရေး၊ငွေရေးကြေးရေး၊ထီပေါက်ကိန်း၊လူမှုရေး၊
                အထွေထွေ အဟောများ မေးမြန်းနိုင်ပါသည်။
              </p>

              <span id="btn-direct" @click="$router.push({ name: 'direct' })">
                မေးမည်
              </span>
            </div>
          </div>
        </div>
        <!-- .col-* -->
        <!-- <div class="col-12" v-if="category == 'business'">
          <div
            class="vertical-item text-center service-layout-1 box-shadow content-padding"
          >
            <div class="item-media">
              <img
                src="@/images/category/business.png"
                alt="img"
                @click="
                  $router.push({ name: 'special', params: { categoryId: 267 } })
                "
              />
            </div>
            <div class="item-content" id="content">
              <p class="mt-0 links-maincolor2" id="head">လုပ်ငန်းအမည်ပေး</p>
              <p id="desc">
                လုပ်ငန်းအမည်ပေးအတွက် ဉာဏ်ပူဇော်ခ ၄၅၀၀ ကျပ်ကျသင့်ပါမည်။
              </p>

              <span
                id="btn-business"
                @click="
                  $router.push({ name: 'special', params: { categoryId: 267 } })
                "
                >မေးမည်</span
              >
            </div>
          </div>
        </div> -->
        <!-- .col-* -->

        <div class="col-12" v-if="category == 'orders'">
          <OrderByCategory/>
        </div>
        <!-- .col-* -->
        <div class="col-12" v-if="category == 'zoom'">
          <div
            class="vertical-item text-center service-layout-1 box-shadow content-padding"
          >
            <div class="item-media">
              <img
                src="@/images/category/zoom.png"
                alt="img"
                @click="$router.push({ name: 'zoom' })"
              />
            </div>
            <div class="item-content" id="content">
              <p class="mt-0 links-maincolor2" id="head">
                Video Call ဖြင့်ဗေဒင်မေးမည်
              </p>
              <p id="desc">
                Video Call တိုက်ရိုက်မေးမြန်းမှုအတွက် (၁) ရက်ကြိုတင်၍ Booking
                လုပ်ပေးပါရန်။
              </p>

              <span id="btn-zoom" @click="$router.push({ name: 'baydinsayar' })"
                >မေးမည်</span
              >
            </div>
          </div>
        </div>
        <!-- .col-* -->

        <div class="col-12" v-if="category == 'newyearbaydin'">
          <div
            class="vertical-item text-center service-layout-1 box-shadow content-padding"
          >
            <div class="item-media">
              <img
                src="@/images/category/newyear-2024.jpg"
                alt="img"
                @click="$router.push({ name: 'NewYearBaydin' })"
              />
            </div>
            <div class="item-content" id="content">
              <p class="mt-0 links-maincolor2" id="head">တစ်နှစ်စာ ဟောစာတမ်း</p>
              <p id="desc">
                သင်၏ ဟောစာတမ်းအတွက် ဉာဏ်ပူဇော်ခ ၅၀၀၀ ကျပ်ကျသင့်ပါမည်။
                <!-- နည်းပညာအခက်အခဲကြောင့် ဝန်ဆောင်မှု ယာယီရပ်ဆိုင်း၍
                ပြုပြင်နေပါသည်။အဆင်မပြေမှုများအတွက်တောင်ပန်အပ်ပါသည်။ -->
              </p>

              <span
                id="btn-business"
                @click="$router.push({ name: 'NewYearBaydin' })"
                >မေးမည်</span
              >
            </div>
          </div>
        </div>
        <!-- .col-* -->

        <div class="col-12" v-if="category == 'nawin'">
          <div
            class="vertical-item text-center service-layout-1 box-shadow content-padding"
          >
            <div class="item-media">
              <img
                src="@/images/category/nawin.jpg"
                alt="img"
                @click="$router.push({ name: 'Nawin' })"
              />
            </div>
            <div class="item-content" id="content">
              <p class="mt-0 links-maincolor2" id="head">နဝင်းယတြာ</p>
              <p id="desc">
                နဝင်းယတြာ ပြုလုပ်ရန်အတွက် ဉာဏ်ပူဇော်ခ ၁၈၀၀၀ ကျပ် ကျသင့်ပါမည်။
              </p>
              <!-- <p class="mt-0 links-maincolor2" id="head">Testing</p>
              <p id="desc">Testing Service</p> -->

              <span id="btn-business" @click="$router.push({ name: 'Nawin' })"
                >မေးမည်</span
              >
            </div>
          </div>
        </div>
        <!-- .col-* -->
      </div>
    </div>
  </section>
</template>

<script>
import KbzEng from '../UI/KbzEng.vue';
import KbzMM from '../UI/KbzMM.vue';
import OrderByCategory from './OrderByCategory.vue'

export default {
  name: `Category`,
  components: {
    KbzEng,
    KbzMM,
    OrderByCategory
  },
  data() {
    return {
      category: this.$route.params.categoryName,
      isMM: false
    };
  },

  mounted() {
    this.getLocale();
  },
  methods: {
    getLocale() {
        window.ma.callNativeAPI("getLocale", {}, res => {
        this.isMM = res.language === "my-MM";
      })
    }
  },
};
</script>

<style scoped>
.container {
  /* background-color: #fafafa; */
}

#content {
  padding: 15px;
}
#desc {
  font-weight: normal;
}

#head {
  font-size: 18px;
}

#btn-oneyear {
  background-color: #46892f;
  border-color: #46892f;
  padding: 8px 16px;
  display: inline-block;
}
#btn-clairvoyance {
  background-color: #ff8f00;
  border-color: #ff8f00;
  padding: 8px 16px;
  display: inline-block;
}
#btn-free {
  background-color: #fe61db;
  border-color: #fe61db;
  padding: 8px 16px;
  display: inline-block;
}
#btn-chiromancy {
  background-color: #925fff;
  border-color: #925fff;
  padding: 8px 16px;
  display: inline-block;
}
#btn-child {
  background-color: #767676;
  border-color: #767676;
  padding: 8px 16px;
  display: inline-block;
}
#btn-love {
  background-color: #ff4f4f;
  border-color: #ff4f4f;
  padding: 8px 16px;
  display: inline-block;
}
#btn-phone {
  background-color: #f49893;
  border-color: #f49893;
  padding: 8px 16px;
  display: inline-block;
}
#btn-direct,
#btn-zoom {
  background-color: #6bb2ff;
  border-color: #6bb2ff;
  padding: 12px 30px;
  display: inline-block;
}
#btn-business {
  background-color: #ff4f4f;
  border-color: #ff4f4f;
  padding: 12px 30px;
  display: inline-block;
}
#btn-orders {
  background-color: #d117c5;
  border-color: #d117c5;
  padding: 12px 30px;
  display: inline-block;
}

li {
  font-size: 14px;
  line-height: 1.7;
}

li > b {
  font-size: 1rem;
}

h6 {
  font-size: 20px;
  line-height: 1.3;
}

@media screen and (max-width: 480px) {
  li, li > b {
  font-size: 0.78rem;
  line-height: 1.4;
}

li {
  font-size: 11px;
}

h6 {
  font-size: 15px;
}
}
</style>

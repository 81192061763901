<template>
  <section class="ds s-py-45 s-py-xl-75 c-gutter-60">
    <div class="container">
      <div class="row">
        <main class="col-lg-12">
          <article>
            <div class="entry-content">
              <div class="woocommerce">
                <div class="woocommerce-MyAccount-content">
                  <form id="child-form" role="form" style="display: block">
                    <p class="mm-font">
                      အောက်ပါပေးထားသော Form အချက်အလက်များကို ဖြည့်ပေးပါ
                    </p>
                    <label class="form-group">
                      <label class="mm-font">အမည်</label>
                      <input
                        type="text"
                        class="form-control"
                        name="name"
                        v-model="star.name"
                        required
                      />
                      <span class="error__message" v-if="!star.name && errors.name">
                        <i class="pi pi-exclamation-circle mr-1"></i>
                        {{ errors.name }}
                      </span>
                    </label>
                    <label class="form-group">
                      <label class="mm-font">မွေးသက္ကရာဇ်</label>
                      <md-datepicker
                        :md-disabled-dates="disablePastDates"
                        value-format="YYYY-MM-DD"
                        class="input-container"
                        v-model="star.birth_date"
                        ref="datepicker"
                      >
                        <label class="date__picker" v-if="!star.birth_date">YYYY-MM-DD</label>
                      </md-datepicker>
                      <div class="error__message" v-if="!star.birth_date && errors.birth_date">
                        <i class="pi pi-exclamation-circle mr-1"></i>
                        {{ errors.birth_date }}
                      </div>
                    </label>
                    <label class="form-group">
                      <label class="mm-font">နေ့နံ</label>
                      <div class="dropdown__container">
                        <select
                          class="mm-font __mm"
                          name="nyih_nan"
                          v-model="star.nyih_nan"
                          required
                        >
                          <option value="" class="__mm" selected>
                            နေ့နံရွေးချယ်ပါ
                          </option>
                          <option value="တနင်္ဂနွေ" class="__mm">
                            တနင်္ဂနွေ
                          </option>
                          <option value="တနင်္လာ" class="__mm">တနင်္လာ</option>
                          <option value="အင်္ဂါ" class="__mm">အင်္ဂါ</option>
                          <option value="ဗုဒ္ဓဟူး" class="__mm">ဗုဒ္ဓဟူး</option>
                          <option value="ရာဟု" class="__mm">ရာဟု</option>
  
                          <option value="ကြာသပတေး" class="__mm">ကြာသပတေး</option>
                          <option value="သောကြာ" class="__mm">သောကြာ</option>
                          <option value="စနေ" class="__mm">စနေ</option>
                        </select>
                        <i class="pi pi-chevron-down dropdown__icon"></i>
                      </div>
                      <span class="error__message" v-if="!star.nyih_nan && errors.nyih_nan">
                          <i class="pi pi-exclamation-circle mr-1"></i>
                          {{ errors.nyih_nan }}
                      </span>
                    </label>
                    <input
                      type="radio"
                      name="marital_status"
                      value="Yes"
                      v-model="star.marital_status"
                      required
                    />
                    <span for="" class="mm-font"> အိမ်ထောင်ရှိ </span>
                    <input
                      type="radio"
                      name="marital_status"
                      value="No"
                      v-model="star.marital_status"
                      required
                    />
                    <span for="" class="mm-font"> အိမ်ထောင်မရှိ </span>
                    <div class="error__message ml-1" v-if="!star.marital_status && errors.marital_status">
                        <i class="pi pi-exclamation-circle mr-1"></i>
                        {{ errors.marital_status }}
                    </div>
                    <br />
                    <br />
                    <br />
                    <label class="form-group">
                      <label for="" class="mm-font">အလုပ်အကိုင်</label>
                      <div
                        style="
                          display: grid;
                          grid-template-rows: 1fr 1fr;
                          gap: 5px;
                        "
                      >
                      <div class="dropdown__container">
                        <select
                          name="job"
                          class="mm-font __mm"
                          v-model="star.job"
                          required
                        >
                          <option value="" selected hidden>
                            အလုပ်အကိုင် အမျိုးအစား
                          </option>
                          <option value="အစိုးရဝန်ထမ်း">အစိုးရဝန်ထမ်း</option>
                          <option value="ကုမ္ပဏီဝန်ထမ်း">ကုမ္ပဏီဝန်ထမ်း</option>
                          <option value="ကိုယ်ပိုင်စီးပွားရေး">
                            ကိုယ်ပိုင်စီးပွားရေး
                          </option>
                          <option value="ကျောင်းသား/သူ">ကျောင်းသား/သူ</option>
                          <option value="အခြား">အခြား</option>
                        </select>
                        <i class="pi pi-chevron-down dropdown__icon"></i>
                      </div>
                        <input
                          type="text"
                          class="form-control"
                          name="job_detail"
                          v-model="star.job_detail"
                          placeholder="အလုပ်ကိုင်အသေးစိတ် "
                        />
                        <div class="error__message" v-if="(!star.job || !star.job_detail) && errors.job">
                          <i class="pi pi-exclamation-circle mr-1 mb-1"></i>
                          {{ errors.job }}
                        </div>
                      </div>
                    </label>
                    <input
                      type="radio"
                      name="gender"
                      value="Male"
                      v-model="star.gender"
                      required
                    />
                    <span for="" class="mm-font">ကျား</span>
                    <input
                      type="radio"
                      name="gender"
                      value="Female"
                      required
                      v-model="star.gender"
                    />
                    <span for="" class="mm-font"> မ </span>
                    <div class="error__message ml-1" v-if="!star.gender && errors.gender">
                      <i class="pi pi-exclamation-circle mr-1 mb-1"></i>
                      {{ errors.gender }}
                    </div>
                    <br />

                    <p
                      class="mm-font __mm"
                      v-if="this.categoryName == 'clairvoyance'"
                    >
                      မေးလိုသည့်ကဏ္ဍကို ရွေးချယ်ပါ။
                    </p>

                    <div
                      class="container options"
                      ref="input"
                      v-if="this.categoryName == 'clairvoyance'"
                    >
                      <label
                        class="form-check-label customcheck"
                        v-for="type in getService"
                        :key="type.id"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          :value="type.price"
                          name="services[]"
                          v-model="type.selected"
                          @click="toggle(type)"
                        />
                        <span class="checkmark"></span>
                        <span class="mm-font __mm">{{ type.name }}</span>
                      </label>
                    </div>

                    <br />
                    <p class="mm-font">မေးမြန်းလိုသည့်အကြာင်းအရာကိုရေးပါ။</p>
                    <label class="form-group">
                      <textarea
                        name="about"
                        v-model="star.about"
                        class="mm-font test"
                        rows="10"
                        cols="35"
                        placeholder=""
                        style="color: black"
                        required
                      ></textarea>
                      <div class="error__message" v-if="!star.about && errors.about">
                        <i class="pi pi-exclamation-circle mr-1 mb-1"></i>
                        {{ errors.about }}
                      </div>
                    </label>
                    <label class="form-group">
                      <label for="" class="mm-font __mm">
                        Viber အသုံးပြုသော ဖုန်းနံပါတ်အားထည့်ပါ</label
                      >
                      <input
                        type="number"
                        class="form-control"
                        name="phone_number"
                        v-model="star.phone_number"
                        placeholder="09xxxxxxxxx"
                        @input="validateForm"
                        required
                      />
                      <div class="error__message error__phoneNo" v-if="errors.phone_number">
                        <i class="pi pi-exclamation-circle mr-1 mb-1"></i>
                        {{ errors.phone_number }}
                      </div>
                    </label>
                    <p class="t_c_agreement">By continuing, you agree to <span @click.prevent="redirectTNC()">Terms and Conditions.</span></p>
                    <div v-if="this.categoryName == 'chiromancy'">
                      ဉာဏ်ပူဇော်ခ 10000 ကျပ် ဖြစ်ပါသည်။
                    </div>
                    <div v-if="this.categoryName == 'clairvoyance'">
                      ဉာဏ်ပူဇော်ခ {{ star.price }} ကျပ် ဖြစ်ပါသည်။
                    </div>

                    <p>
                      <input
                        type="submit"
                        @click.prevent="paymentProvider()"
                        :disabled="disabled"
                        class="woocommerce-Button button mm-font __mm"
                        name="save_account_details"
                        value="အတည်ပြုမည်"
                      />
                    </p>
                  </form>
                  <br />
                  <div v-if="this.categoryName == 'chiromancy'">
                    <div class="col-md-12">
                      <p class="mm-font __mm cat">
                        လက္ခဏာ လမ်းကြောင်းကို ကြည်လင်ပြတ်သားစွာရိုက်ထားသော
                        ဇာတာရှင်၏ လက်နှစ်ဘက်ပုံနှင့် အမည်အား Viber- 09456880335
                        (သို့မဟုတ်) 1875 Bay Din Messenger သို့ပို့ပေးရန်။
                      </p>
                    </div>
                  </div>
                  <div v-if="this.categoryName == 'clairvoyance'">
                    <div class="col-md-12">
                      <p class="mm-font __mm cat">
                        မိမိသိလိုသော မေးခွန်းအားလုံးကိုရေးပေးပြီး မျက်နှာကို
                        ကြည်လင်ပြတ်သားစွာရိုက်ထားသော ဇာတာရှင်၏
                        ဓာတ်ပုံနှင့်အမည်ကို Viber- 09456880335 (သို့မဟုတ်)
                        1875 Bay Din Messenger သို့ပို့ပေးရန်။
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- .entry-content -->
          </article>
        </main>
      </div>
    </div>
  </section>
</template>

<script>
import types from "@core/i18n/resource/akyaramyin.json";
import {mapGetters} from "vuex";
import {Horo, HTTP} from "@core/lib/http-common";
import {checkFormModel, getFormData} from "@core/lib/form-data";
import {citizenPay, makePayment, onePay} from "../js/payment";
import {EventBus} from "../../public/js/eventBus";
import { validatePhoneNumber } from "../utils/validationPhone";

export default {
  name: `Special`,
  data() {
    return {
      datepicker: null,
      actionBtnMethod: "",
      disabled: false,
      getService: [],
      onepayPhoneNumber: "",
      star: {
        name: "",
        birth_date: "",
        nyih_nan: "",
        phone_number: "",
        order_id: "0",
        gender: "",
        user_id: "0",
        job: "",
        job_detail: "",
        about: "",
        services: [],
        baydin_sayar: "",
        marital_status: "",
        price: 0,
      },
      errors: {},
      isMM: false
    };
  },
  mounted() {
    if (this.$refs.datepicker) {
      const input = this.$refs.datepicker.$el.querySelector("input");
      if (input) {
        input.setAttribute("readonly", "true");
      }
    }

    this.actionBtnMethod = this.$store.state.paymentName;
    this.onepayPhoneNumber =
      this.$store.state.paymentName == "onepay"
        ? this.$store.state.onepayPhoneNumber
        : "";
    if (this.categoryName == "chiromancy") {
      this.star.price = 5000;
      this.star.baydin_sayar = "ဆရာ ရဲရန်အောင်";
    }
    if (this.categoryName == "clairvoyance") {
      this.star.baydin_sayar = "ရှမ်းပုဂ္ဂိုလ်ဓါတ်စီး ဆရာမ ငုဝါ";
      if (this.$store.state.paymentName == " ") {
        this.getService = types[0].getService;
      } else {
        types.map((v) => {
          if (v.payment == this.$store.state.paymentName) {
            this.getService = v.getService;
          }
        });
      }
    }
    this.getLocale();
  },
  computed: {
    categoryName() {
      return this.$route.params.categoryName;
    },
    ...mapGetters({
      getUserToken: "userStore/getUserToken",
      getResponse: "userStore/getResponse",
    }),

    disablePastDates() {
      const today = new Date();
      return (date) => new Date(date) >= today;
    }
  },
  methods: {
    getLocale() {
        window.ma.callNativeAPI("getLocale", {}, res => {
        this.isMM = res.language === "my-MM";
      })
    },

    redirectTNC() {
        this.$router.push('/category/terms_and_conditions')
    },

    toggle(type) {
      if (!type.selected) {
        this.star.price += type.price;
        this.star.services.push(type.servicename);
      } else {
        this.star.price -= type.price;
        this.star.services = this.star.services.filter(
          (item) => item !== type.servicename
        );
      }
    },

    paymentProvider() {
      this.validateForm();
      let getPaymentName = this.actionBtnMethod;
      switch (getPaymentName) {
        case "mpitesan":
          this.makePayment();
          break;
        case "onepay":
          this.onePayProvider();
          break;
        case "citizenpay":
          this.citizenPayProvicer();
          break;
        case "bppay":
          this.bpPay();
          break;
        default:
          this.precreate();
          break;
      }
    },

    async citizenPayProvicer() {
      if (checkFormModel(this.star)) {
        this.disabled = true;

        let formdata = getFormData(this.star);
        formdata.append("user_phone", this.star.phone_number);
        let payment_method =
          this.$store.state.paymentName == " "
            ? "kbz"
            : this.$store.state.paymentName;
        formdata.append("payment_method", payment_method);
        let cdata = {
          category_id: 99999,
          amount: this.star.price,
          category_name: this.categoryName,
          // phone_number:
          //   this.onepayPhoneNumber == ""
          //     ? this.direct.phone_number
          //     : this.onepayPhoneNumber,
        };
        await citizenPay([
          "1875/starbaydin/horoscope",
          formdata,
          cdata,
          this.$store.state.backendUrl,
          this.star.price,
        ])
          .then((data) => {
            if (data.status == 200) {
              window.location.href = data.paymentGatway;
            } else {
              this.$swal("Oops", "Something went wrong!", "error");
            }
          })
          .catch(() => {
            this.$swal("Oops", "Something went wrong!", "error");
          });
      } else {
        this.$swal("Oops", "You need to fill all fields", "error");
      }
    },

    async onePayProvider() {
      if (checkFormModel(this.star)) {
        this.disabled = true;

        let formdata = getFormData(this.star);
        formdata.append("user_phone", this.star.phone_number);
        let payment_method =
          this.$store.state.paymentName == " "
            ? "kbz"
            : this.$store.state.paymentName;
        formdata.append("payment_method", payment_method);
        let cdata = {
          category_id: 99999,
          amount: this.star.price,
          category_name: this.categoryName,
          phone_number:
            this.onepayPhoneNumber == ""
              ? this.star.phone_number
              : this.onepayPhoneNumber,
        };
        await onePay([
          "1875/starbaydin/horoscope",
          formdata,
          cdata,
          this.$store.state.backendUrl,
          this.star.price,
        ])
          .then((data) => {
            if (data.status == 200) {
              // console.log(data);
              this.$store.state.order_id = data.order_id;
              this.$store.state.amount = data.amount;
              // this.$router.push({
              //   name: "paymentPortal",
              // });
            } else {
              this.$swal("Oops", "Something went wrong!", "error");
            }
          })
          .catch(() => {
            this.$swal("Oops", "Something went wrong!", "error");
          });
      } else {
        this.$swal("Oops", "You need to fill all fields", "error");
      }
    },

    async bpPay() {
      if (checkFormModel(this.star)) {
        this.disabled = true;

        let formdata = getFormData(this.star);
        formdata.append("user_phone", this.star.phone_number);
        let payment_method =
          this.$store.state.paymentName == " "
            ? "kbz"
            : this.$store.state.paymentName;
        formdata.append("payment_method", payment_method);
        let cdata = {
          category_id: 99999,
          amount: this.star.price,
          category_name: this.categoryName,
          // promocode: this.promocode,
          phone_number: this.star.phone_number,
          payment_name: this.actionBtnMethod,
        };
        await makePayment([
          "1875/starbaydin/horoscope",
          formdata,
          cdata,
          this.$store.state.backendUrl,
          this.star.price,
        ])
          .then((data) => {
            if (data.status == 200) {
              // console.log(data);
              this.$store.state.order_id = data.order_id;
              this.$store.state.amount = data.amount;
              if (!data.payment_status) {
                this.$router.push({
                  name: "paymentPortalV3",
                });
              } else {
                this.$router.push({
                  name: "PaymentSuccess",
                  params: { payment: "PWA BPPay" },
                });
              }
            } else {
              this.$swal("Oops", "Something went wrong!", "error");
            }
          })
          .catch(() => {
            this.$swal("Oops", "Something went wrong!", "error");
          });
      } else {
        this.$swal("Oops", "You need to fill all fields", "error");
      }
    },

    async makePayment() {
      if (checkFormModel(this.star)) {
        this.disabled = true;

        let formdata = getFormData(this.star);
        formdata.append("user_phone", this.star.phone_number);
        let payment_method =
          this.$store.state.paymentName == " "
            ? "kbz"
            : this.$store.state.paymentName;
        formdata.append("payment_method", payment_method);
        let cdata = {
          category_id: 99999,
          amount: this.star.price,
          category_name: this.categoryName,
          // promocode: this.promocode,
          phone_number: this.star.phone_number,
          payment_name: this.actionBtnMethod,
        };
        await makePayment([
          "1875/starbaydin/horoscope",
          formdata,
          cdata,
          this.$store.state.backendUrl,
          this.star.price,
        ])
          .then((data) => {
            if (data.status == 200) {
              // console.log(data);
              this.$store.state.order_id = data.order_id;
              this.$store.state.amount = data.amount;
              if (!data.payment_status) {
                this.$router.push({
                  name: "paymentPortal",
                });
              } else {
                this.$router.push({
                  name: "PaymentSuccess",
                  params: { payment: "mpitesan" },
                });
              }
            } else {
              this.$swal("Oops", "Something went wrong!", "error");
            }
          })
          .catch(() => {
            this.$swal("Oops", "Something went wrong!", "error");
          });
      } else {
        this.$swal("Oops", "You need to fill all fields", "error");
      }
    },
    precreate() {
      if (checkFormModel(this.star)) {
        this.disabled = true;
        HTTP.defaults.headers.Authorization = `Bearer ${this.getUserToken}`;
        HTTP.post("subscription/precreate", {
          category_id: 99999,
          amount: this.star.price,
          category_name: this.categoryName,
        })
          .then((response) => {
            const res = response.data;
            this.star.order_id = res.order_id;
            this.star.user_id = res.user_id;
            let formdata = getFormData(this.star);
            formdata.set("price", res.amount);
            formdata.append(
              "user_phone",
              this.getResponse.customer_info.msisdn
            );
            Horo.post("1875/starbaydin/horoscope", formdata).then(
              (response) => {
                this.res_status = response.data.status;

                // new kbzpay mini_app api
                window.ma.callNativeAPI('startPay', {
                  prepayId: res.prepay_id,
                  orderInfo: res.order_info,
                  sign: res.sign_app,
                  signType: 'SHA256',
                  disableNewCheckout: 'false',
                  tradeType: "MINIAPP"
                  }, res => {
                  console.log("startPay api res: ", res);
                  if (res.resultCode == 1) {
                    EventBus.$emit("clear-cache");
                    console.log("startpaysucess")
                    if (!this.isMM) {
                      this.$swal(
                        "Success",
                        "Purchased. Please contact 09456880335 if you have any problems.",
                        "success"
                      );
                      this.$router.push({ name: "home" });
                    }
                    if (this.isMM) {
                      this.$swal(
                        "အောင်မြင်ပါသည်",
                        "ဝယ်ယူပြီးပါပြီ။ အဆင်မပြေမှုများရှိပါက 09456880335 သို့ဆက်သွယ်ပါ။",
                        "success"
                      );
                      this.$router.push({ name: "home" });
                    }
                  }else {
                    if (!this.isMM) {
                      this.$swal("မအောင်မြင်ပါ", "ထပ်မံကြိုးစားပေးပါ", "error");
                      this.disabled = false;
                    }
                    if (this.isMM) {
                      this.$swal(
                        "မအောင်မြင်ပါ",
                        "ထပ်မံကြိုးစားပေးပါ",
                        "error"
                      );
                      this.disabled = false;
                    }
                  }
                });
                // this.kbzpay.startPay(
                //   res.prepay_id,
                //   res.order_info,
                //   res.sign_app,
                //   ({ resultCode, lang }) => {
                //     if (resultCode == 1) {
                //       if (lang == "en") {
                //         this.$swal(
                //           "Success",
                //           "Your Purchase Success",
                //           "success"
                //         );
                //         this.$router.push({ name: "home" });
                //       }
                //       if (lang == "my") {
                //         this.$swal(
                //           "အောင်မြင်ပါသည်",
                //           "ဝယ်ယူပြီးပါပြီ",
                //           "success"
                //         );
                //         this.$router.push({ name: "home" });
                //       }
                //     }
                //     if (resultCode == 2) {
                //       if (lang == "en") {
                //         this.$swal("Failed", "Your Purchase Failed", "error");
                //         this.disabled = false;
                //       }
                //       if (lang == "my") {
                //         this.$swal(
                //           "မအောင်မြင်ပါ",
                //           "ထပ်မံကြိုးစားပေးပါ",
                //           "error"
                //         );
                //         this.disabled = false;
                //       }
                //     }
                //   },
                //   () => {
                //     this.$swal("Failed", "Your Purchase Failed", "error");
                //     this.disabled = false;
                //   }
                // );
              }
            );
          })
          .catch((error) => {
            console.log(error);
            this.message = "Something went wrong";
          });
      } else {
        if(this.isMM) {
          this.$swal(
          "Oops",
          "လိုအပ်သည့်အချက်အလက်များ အားလုံးဖြည့်ပေးပါ။",
          "error"
        );
        } else {
          this.$swal(
          "Oops",
          "You need to fill all fields.",
          "error"
        );
        }
      }
    },

    validateForm() {
      this.errors = {};
      
      if(this.star.name === '' || !this.star.name) this.errors.name = 'အမည်ထည့်ရန်လိုအပ်ပါသည်။'
      if(this.star.birth_date === '' || !this.star.birth_date) this.errors.birth_date = 'မွေးသက္ကရာဇ်ထည့်ရန်လိုအပ်ပါသည်။'
      if(this.star.nyih_nan === '' || !this.star.nyih_nan) this.errors.nyih_nan = 'နေ့နံရွေးချယ်ရန်လိုအပ်ပါသည်။'
      if(this.star.marital_status === '' || !this.star.marital_status) this.errors.marital_status = "အိမ်ထောင်ရှိ/မရှိ ရွေးချယ်ရန်လိုအပ်ပါသည်။"
      if((this.star.job === '' || !this.star.job) || (this.star.job_detail === '' || !this.star.job_detail)) this.errors.job = "အလုပ်အကိုင်အမျိုးအစားရွေးချယ်ရန်လိုအပ်ပါသည်။"
      if(this.star.gender === '' || !this.star.gender) this.errors.gender = "ကျား/မ ရွေးချယ်ရန်လိုအပ်ပါသည်။"
      if(!this.star.about || this.star.about === '') this.errors.about = "မေးမြန်းလိုသောအကြောင်းအရာထည့်ရန်လိုအပ်ပါသည်။"
      this.errors.phone_number = validatePhoneNumber(this.star.phone_number)
    }
  },
};
</script>

<style scoped>
input[type="radio"] {
  display: inline;
  margin-top: 8px;
  margin-left: 3px;
}
input[type="checkbox"] {
  display: inline;
  margin-top: 8px;
}

label {
  display: block;
}

.input-container {
  margin-bottom: 0.5em !important;
}

.error__phoneNo {
  margin-top: 0.5em;
}

.md-field.md-theme-default:after {
  background-color: rgba(0, 0, 0, 0);
}
.md-button.md-theme-default {
  margin: 0px 16px 0px 0px;
}

.date__picker {
	margin-top: -8px !important;
  margin-left: 12px !important;
}
</style>

<template>
  <section class="ds s-py-45 s-py-xl-75 c-gutter-60">
    <div class="container">
      <div class="row">
        <main class="col-lg-12">
          <article>
            <div class="entry-content">
              <div class="woocommerce">
                <div class="woocommerce-MyAccount-content">
                  <form id="child-form" role="form" style="display: block">
                    <p class="mm-font __mm">
                      အောက်ပါပေးထားသော Form အချက်အလက်များကို ဖြည့်ပေးပါ
                    </p>
                    <label class="form-group">
                      <span class="mm-font __mm">အမည်</span>
                      <input
                        type="text"
                        class="form-control"
                        name="name"
                        v-model="phone.name"
                        required
                      />
                      <span class="error__message" v-if="!phone.name && errors.name">
                        <i class="pi pi-exclamation-circle mr-1"></i>
                        {{ errors.name }}
                      </span>
                    </label>
                    <label class="form-group">
                      <span class="mm-font __mm">မွေးသက္ကရာဇ်</span>
                      <md-datepicker
                        :md-disabled-dates="disablePastDates"
                        value-format="YYYY-MM-DD"
                        class="input-container"
                        v-model="phone.birth_date"
                        ref="datepicker"
                      >
                      <label class="date__picker" v-if="!phone.birth_date">YYYY-MM-DD</label>
                      </md-datepicker>
                      <div class="error__message" v-if="!phone.birth_date && errors.birth_date">
                        <i class="pi pi-exclamation-circle mr-1 mb-1"></i>
                        {{ errors.birth_date }}
                      </div>
                    </label>
                    <label class="form-group">
                      <span class="mm-font __mm">နေ့နံ</span>
                      <div class="dropdown__container">
                        <select
                          class="mm-font __mm"
                          name="nyih_nan"
                          v-model="phone.nyih_nan"
                          required
                        >
                          <option value="" class="__mm" selected>
                            နေ့နံရွေးချယ်ပါ
                          </option>
                          <option value="တနင်္ဂနွေ" class="__mm">
                            တနင်္ဂနွေ
                          </option>
                          <option value="တနင်္လာ" class="__mm">တနင်္လာ</option>
                          <option value="အင်္ဂါ" class="__mm">အင်္ဂါ</option>
                          <option value="ဗုဒ္ဓဟူး" class="__mm">ဗုဒ္ဓဟူး</option>
                          <option value="ရာဟု" class="__mm">ရာဟု</option>
  
                          <option value="ကြာသပတေး" class="__mm">ကြာသပတေး</option>
                          <option value="သောကြာ" class="__mm">သောကြာ</option>
                          <option value="စနေ" class="__mm">စနေ</option>
                        </select>
                        <i class="pi pi-chevron-down dropdown__icon"></i>
                      </div>
                      <span class="error__message" v-if="!phone.nyih_nan && errors.nyih_nan">
                        <i class="pi pi-exclamation-circle mr-1"></i>
                        {{ errors.nyih_nan }}
                      </span>
                    </label>
                    <input
                      type="radio"
                      name="marital_status"
                      v-model="phone.marital_status"
                      value="Yes"
                      required
                    />
                    <span for class="mm-font __mm">အိမ်ထောင်ရှိ</span>
                    <input
                      type="radio"
                      name="marital_status"
                      v-model="phone.marital_status"
                      value="No"
                      required
                    />
                    <span for class="mm-font __mm">အိမ်ထောင်မရှိ</span>
                    <div class="error__message ml-1" v-if="!phone.marital_status && errors.marital_status">
                      <i class="pi pi-exclamation-circle mr-1"></i>
                      {{ errors.marital_status }}
                    </div>
                    <br />
                    <br />
                    <label class="form-group">
                      <span for class="mm-font __mm">နေရပ်လိပ်စာ</span>
                      <div class="dropdown__container">
                        <select
                          v-model="phone.address_division"
                          required
                          class="mm-font __mm"
                          @change="handleDivisionChange"
                        >
                          <option value="" selected hidden>
                            ပြည်နယ်/တိုင်း
                          </option>
                          <option
                            v-for="d in divisions"
                            :data-nrc="d.nrc_id"
                            :value="d.name"
                            :key="d.name"
                            class="__mm"
                          >
                            {{ d.name }}
                          </option>
                        </select>
                        <i class="pi pi-chevron-down dropdown__icon"></i>
                      </div>
                      <div class="dropdown__container">
                        <select
                          v-model="phone.address_township"
                          required
                          class="mm-font __mm"
                        >
                          <option value="" selected hidden>မြို့နယ်</option>
                          <option
                            value=""
                            disabled
                            v-if="townships.length == 0"
                          >
                            တိုင်း/ ပြည်နယ် ကိုအရင်ရွေးချယ်ပါ
                          </option>
                          <option
                            v-for="t in townships"
                            :value="t.name"
                            :key="t.name"
                            class="__mm"
                          >
                            {{ t.name }}
                          </option>
                        </select>
                        <i class="pi pi-chevron-down dropdown__icon"></i>
                      </div>
                      <input
                        type="text"
                        class="mm-font __mm form-control"
                        placeholder="ရပ်ကွက်/ လမ်း အသေးစိတ်"
                        v-model="phone.address"
                        required
                      />
                      <div class="error__message" v-if="(!phone.address || !this.phone.address_division || !this.phone.address_township) && errors.address">
                        <i class="pi pi-exclamation-circle mr-1"></i>
                        {{ errors.address }}
                    </div>
                    </label>
                    <input
                      type="radio"
                      name="gender"
                      value="Male"
                      v-model="phone.gender"
                      required
                    />
                    <span for class="mm-font __mm">ကျား</span>
                    <input
                      type="radio"
                      name="gender"
                      value="Female"
                      v-model="phone.gender"
                      required
                    />
                    <span for class="mm-font __mm">မ</span>
                    <div class="error__message ml-1" v-if="!phone.gender && errors.gender">
                      <i class="pi pi-exclamation-circle mr-1 mb-1"></i>
                      {{ errors.gender }}
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <label class="form-group">
                      <span for class="mm-font __mm"
                        >ဆက်သွယ်ရမည့်ဖုန်းနံပါတ်</span
                      >
                      <input
                        type="number"
                        class="form-control"
                        name="phone_number"
                        v-model="phone.phone_number"
                        placeholder="09xxxxxxxxx"
                        required
                        @input="validateForm"
                      />
                      <div class="error__message" v-if="errors.phone_number">
                        <i class="pi pi-exclamation-circle mr-1 mb-1"></i>
                        {{ errors.phone_number }}
                      </div>
                    </label>

                    <br />
                    <br />
                    <div class="form-group">
                      <h6 class="mm-font __mm" style="padding: 0; margin: 0">
                        ဗေဒင်ဆရာ/မ များမှ ဖုန်းခေါ်ဆို ပေးစေချင်သောအချိန်
                      </h6>
                      <p class="mm-font __mm" style="padding: 0; margin: 0">
                        တနင်္လာ မှ စနေထိ၊မနက် ၉နာရီ မှ ညနေ ၅နာရီအတွင်းသာ
                      </p>
                      <div
                        style="
                          display: grid;
                          grid-template-columns: 1fr 1fr;
                          gap: 10px;
                        "
                      >
                      <div class="dropdown__container">
                        <select
                          id="call-time-day"
                          class="mm-font __mm"
                          v-model="call_time_day"
                          required
                        >
                          <option value="" hidden selected>နေ့</option>
                          <option value="တနင်္လာ">တနင်္လာ</option>
                          <option value="အင်္ဂါ">အင်္ဂါ</option>
                          <option value="ဗုဒ္ဓဟူး">ဗုဒ္ဓဟူး</option>
                          <option value="ကြာသပတေး">ကြာသပတေး</option>
                          <option value="သောကြာ">သောကြာ</option>
                          <option value="စနေ">စနေ</option>
                        </select>
                        <i class="pi pi-chevron-down dropdown__icon"></i>
                      </div>
                      <div class="dropdown__container">
                        <select
                          id="call-time-time"
                          class="mm-font __mm"
                          v-model="call_time_time"
                          required
                        >
                          <option value="" hidden selected>အချိန်</option>
                          <option value="9-12">
                            နံနက် ၉ နာရီမှ ၁၂ နာရီအတွင်း
                          </option>
                          <option value="12-5">
                            ညနေ ၁၂ နာရီမှ ၅ နာရီအတွင်း
                          </option>
                        </select>
                        <i class="pi pi-chevron-down dropdown__icon"></i>
                      </div>
                      </div>
                      <span class="error__message" v-if="(!call_time_day || !call_time_time) && errors.time">
                        <i class="pi pi-exclamation-circle mr-1"></i>
                        {{ errors.time }}
                      </span>
                    </div>

                    <br />
                    <br />
                    <p class="mm-font" id="call_service"></p>
                    <p class="mm-font __mm">
                      မေးမြန်းလိုသည့်အကြာင်းအရာကိုရေးပါ။
                    </p>
                    <label class="form-group">
                      <textarea
                        name="about"
                        v-model="phone.about"
                        class="mm-font test"
                        rows="10"
                        cols="35"
                        placeholder
                        style="color: black"
                        required
                      ></textarea>
                      <div class="error__message" v-if="!phone.about && errors.about">
                        <i class="pi pi-exclamation-circle mr-1 mb-1"></i>
                        {{ errors.about }}
                      </div>
                    </label>
                    <p class="t_c_agreement">By continuing, you agree to <span @click.prevent="redirectTNC()">Terms and Conditions.</span></p>
                    <p>
                      <input
                        type="submit"
                        @click.prevent="paymentProvider()"
                        :disabled="disabled"
                        class="woocommerce-Button button mm-font __mm"
                        name="save_account_details"
                        value="အတည်ပြုမည်"
                      />
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </article>
        </main>
      </div>
    </div>
  </section>
</template>

<script>
import {mapGetters} from "vuex";
import {Horo, HTTP} from "@core/lib/http-common";
import {checkFormModel, getFormData} from "@core/lib/form-data";
import {citizenPay, makePayment, onePay} from "../js/payment";
import {getDivisions, getTownships} from "../js/addressInfo";
import {EventBus} from "../../public/js/eventBus";
import { validatePhoneNumber } from "../utils/validationPhone";

export default {
  name: `Special`,
  data() {
    return {
      datepicker: null,
      actionBtnMethod: "",
      disabled: false,
      onepayPhoneNumber: "",
      divisions: [],
      townships: [],
      call_time_day: "",
      call_time_time: "",
      phone: {
        name: "",
        birth_date: "",
        nyih_nan: "",
        address: "",
        address_division: "",
        address_township: "",
        phone_number: "",
        order_id: "0",
        gender: "",
        call_time: "",
        user_id: "0",
        about: "",
        baydin_sayar: " ",
        marital_status: "",
        price: "5000",
      },
      errors: {},
      isMM: false
    };
  },
  async created() {
    this.divisions = await getDivisions();
    this.actionBtnMethod = this.$store.state.paymentName;
    this.onepayPhoneNumber =
      this.$store.state.paymentName == "onepay"
        ? this.$store.state.onepayPhoneNumber
        : "";
  },
  mounted(){
    if (this.$refs.datepicker) {
      const input = this.$refs.datepicker.$el.querySelector("input");
      if (input) {
        input.setAttribute("readonly", "true");
      }
    }

    this.getLocale()
  },
  computed: {
    ...mapGetters({
      getUserToken: "userStore/getUserToken",
      getResponse: "userStore/getResponse",
    }),

    disablePastDates() {
      const today = new Date();
      return (date) => new Date(date) >= today;
    }
  },
  watch: {
    call_time_day: function (value) {
      this.phone.call_time = value + " " + this.call_time_time;
    },
    call_time_time: function (value) {
      this.phone.call_time = this.call_time_day + " " + value;
    },
  },

  methods: {
    getLocale() {
        window.ma.callNativeAPI("getLocale", {}, res => {
        this.isMM = res.language === "my-MM";
      })
    },

    async handleDivisionChange(e) {
      let nrc_id = e.target.options[e.target.selectedIndex].dataset.nrc;
      this.townships = await getTownships(nrc_id);
    },

    redirectTNC() {
        this.$router.push('/category/terms_and_conditions')
    },

    paymentProvider() {
      this.validateForm();
      let getPaymentName = this.actionBtnMethod;
      switch (getPaymentName) {
        case "mpitesan":
          this.makePayment();
          break;
        case "onepay":
          this.onePayProvider();
          break;
        case "citizenpay":
          this.citizenPayProvicer();
          break;
        case "bppay":
          this.bpPay();
          break;
        default:
          this.precreate();
          break;
      }
    },

    async citizenPayProvicer() {
      if (checkFormModel(this.phone)) {
        this.disabled = true;

        let formdata = getFormData(this.phone);
        formdata.append("user_phone", this.phone.phone_number);
        let payment_method =
          this.$store.state.paymentName == " "
            ? "kbz"
            : this.$store.state.paymentName;
        formdata.append("payment_method", payment_method);
        let cdata = {
          category_id: 44444,
          amount: this.phone.price,
          category_name: "phone",
        };
        await citizenPay([
          "1875/callService/horoscope",
          formdata,
          cdata,
          this.$store.state.backendUrl,
          this.phone.price,
        ])
          .then((data) => {
            if (data.status == 200) {
              window.location.href = data.paymentGatway;
            } else {
              this.$swal("Oops", "Something went wrong!", "error");
            }
          })
          .catch(() => {
            this.$swal("Oops", "Something went wrong!", "error");
          });
      } else {
        this.$swal("Oops", "You need to fill all fields", "error");
      }
    },

    async onePayProvider() {
      if (checkFormModel(this.phone)) {
        this.disabled = true;

        let formdata = getFormData(this.phone);
        formdata.append("user_phone", this.phone.phone_number);
        let payment_method =
          this.$store.state.paymentName == " "
            ? "kbz"
            : this.$store.state.paymentName;
        formdata.append("payment_method", payment_method);
        let cdata = {
          category_id: 44444,
          amount: this.phone.price,
          category_name: "phone",
          phone_number:
            this.onepayPhoneNumber == ""
              ? this.direct.phone_number
              : this.onepayPhoneNumber,
        };
        await onePay([
          "1875/callService/horoscope",
          formdata,
          cdata,
          this.$store.state.backendUrl,
          this.phone.price,
        ])
          .then((data) => {
            if (data.status == 200) {
              // console.log(data);
              this.$store.state.order_id = data.order_id;
              this.$store.state.amount = data.amount;
              // this.$router.push({
              //   name: "paymentPortal",
              // });
            } else {
              this.$swal("Oops", "Something went wrong!", "error");
            }
          })
          .catch(() => {
            this.$swal("Oops", "Something went wrong!", "error");
          });
      } else {
        this.$swal("Oops", "You need to fill all fields", "error");
      }
    },

    async bpPay() {
      if (checkFormModel(this.phone)) {
        this.disabled = true;

        let formdata = getFormData(this.phone);
        formdata.append("user_phone", this.phone.phone_number);
        let payment_method =
          this.$store.state.paymentName == " "
            ? "kbz"
            : this.$store.state.paymentName;
        formdata.append("payment_method", payment_method);
        let cdata = {
          category_id: 44444,
          amount: this.phone.price,
          category_name: "phone",
          // promocode: this.promocode,
          phone_number: this.phone.phone_number,
          payment_name: this.actionBtnMethod,
        };
        await makePayment([
          "1875/callService/horoscope",
          formdata,
          cdata,
          this.$store.state.backendUrl,
          this.phone.price,
        ])
          .then((data) => {
            if (data.status == 200) {
              // console.log(data);
              this.$store.state.order_id = data.order_id;
              this.$store.state.amount = data.amount;
              // this.$router.push({
              //     name: "paymentPortal",
              // });
              if (!data.payment_status) {
                this.$router.push({
                  name: "paymentPortalV3",
                });
              } else {
                this.$router.push({
                  name: "PaymentSuccess",
                  params: { payment: "BPPay" },
                });
              }
            } else {
              this.$swal("Oops", "Something went wrong!", "error");
            }
          })
          .catch(() => {
            this.$swal("Oops", "Something went wrong!", "error");
          });
      } else {
        this.$swal("Oops", "You need to fill all fields", "error");
      }
    },

    async makePayment() {
      if (checkFormModel(this.phone)) {
        this.disabled = true;

        let formdata = getFormData(this.phone);
        formdata.append("user_phone", this.phone.phone_number);
        let payment_method =
          this.$store.state.paymentName == " "
            ? "kbz"
            : this.$store.state.paymentName;
        formdata.append("payment_method", payment_method);
        let cdata = {
          category_id: 44444,
          amount: this.phone.price,
          category_name: "phone",
          // promocode: this.promocode,
          phone_number: this.phone.phone_number,
          payment_name: this.actionBtnMethod,
        };
        await makePayment([
          "1875/callService/horoscope",
          formdata,
          cdata,
          this.$store.state.backendUrl,
          this.phone.price,
        ])
          .then((data) => {
            if (data.status == 200) {
              // console.log(data);
              this.$store.state.order_id = data.order_id;
              this.$store.state.amount = data.amount;
              // this.$router.push({
              //     name: "paymentPortal",
              // });
              if (!data.payment_status) {
                this.$router.push({
                  name: "paymentPortal",
                });
              } else {
                this.$router.push({
                  name: "PaymentSuccess",
                  params: { payment: "mpitesan" },
                });
              }
            } else {
              this.$swal("Oops", "Something went wrong!", "error");
            }
          })
          .catch(() => {
            this.$swal("Oops", "Something went wrong!", "error");
          });
      } else {
        this.$swal("Oops", "You need to fill all fields", "error");
      }
    },
    precreate() {
      if (checkFormModel(this.phone)) {
        this.disabled = true;
        HTTP.defaults.headers.Authorization = `Bearer ${this.getUserToken}`;
        HTTP.post("subscription/precreate", {
          category_id: 44444,
          amount: this.phone.price,
          category_name: "phone",
        })
          .then((response) => {
            const res = response.data;
            this.phone.order_id = res.order_id;
            this.phone.user_id = res.user_id;
            let formdata = getFormData(this.phone);
            formdata.set("price", res.amount);
            formdata.append(
              "user_phone",
              this.getResponse.customer_info.msisdn
            );
            Horo.post("1875/callService/horoscope", formdata).then(
              (response) => {
                this.res_status = response.data.status;

                // new kbzpay mini_app api
                window.ma.callNativeAPI('startPay', {
                  prepayId: res.prepay_id,
                  orderInfo: res.order_info,
                  sign: res.sign_app,
                  signType: 'SHA256',
                  disableNewCheckout: 'false',
                  tradeType: "MINIAPP"
                  }, res => {
                  console.log("startPay api res: ", res);
                  if (res.resultCode == 1) {
                    EventBus.$emit("clear-cache");
                    console.log("startpaysucess")
                    if (!this.isMM) {
                      this.$swal(
                        "Success",
                        "Purchased. Please contact 09456880335 if you have any problems.",
                        "success"
                      );
                      this.$router.push({ name: "home" });
                    }
                    if (this.isMM) {
                      this.$swal(
                        "အောင်မြင်ပါသည်",
                        "ဝယ်ယူပြီးပါပြီ။ အဆင်မပြေမှုများရှိပါက 09456880335 သို့ဆက်သွယ်ပါ။",
                        "success"
                      );
                      this.$router.push({ name: "home" });
                    }
                  }else {
                    if (!this.isMM) {
                      this.$swal("Fail", "Please try again", "error");
                      this.disabled = false;
                    }
                    if (this.isMM) {
                      this.$swal(
                        "မအောင်မြင်ပါ",
                        "ထပ်မံကြိုးစားပေးပါ",
                        "error"
                      );
                      this.disabled = false;
                    }
                  }
                });

                // this.kbzpay.startPay(
                //   res.prepay_id,
                //   res.order_info,
                //   res.sign_app,
                //   ({ resultCode, lang }) => {
                //     if (resultCode == 1) {
                //       if (lang == "en") {
                //         this.$swal(
                //           "Success",
                //           "Your Purchase Success",
                //           "success"
                //         );
                //         this.$router.push({ name: "home" });
                //       }
                //       if (lang == "my") {
                //         this.$swal(
                //           "အောင်မြင်ပါသည်",
                //           "ဝယ်ယူပြီးပါပြီ",
                //           "success"
                //         );
                //         this.$router.push({ name: "home" });
                //       }
                //     }
                //     if (resultCode == 2) {
                //       if (lang == "en") {
                //         this.$swal("Failed", "Your Purchase Failed", "error");
                //         this.disabled = false;
                //       }
                //       if (lang == "my") {
                //         this.$swal(
                //           "မအောင်မြင်ပါ",
                //           "ထပ်မံကြိုးစားပေးပါ",
                //           "error"
                //         );
                //         this.disabled = false;
                //       }
                //     }
                //   },
                //   () => {
                //     this.$swal("Failed", "Your Purchase Failed", "error");
                //     this.disabled = false;
                //   }
                // );
              }
            );
          })
          .catch((error) => {
            console.log(error);
            this.message = "Something went wrong";
          });
      } else {
        if(this.isMM) {
          this.$swal(
          "Oops",
          "လိုအပ်သည့်အချက်အလက်များ အားလုံးဖြည့်ပေးပါ။",
          "error"
        );
        } else {
          this.$swal(
          "Oops",
          "You need to fill all fields.",
          "error"
        );
        }
      }
    },

    validateForm() {
      this.errors = {};
      
      if(this.phone.name === '' || !this.phone.name) this.errors.name = 'အမည်ထည့်ရန်လိုအပ်ပါသည်။'
      if(this.phone.birth_date === '' || !this.phone.birth_date) this.errors.birth_date = 'မွေးသက္ကရာဇ်ထည့်ရန်လိုအပ်ပါသည်။'
      if(this.phone.nyih_nan === '' || !this.phone.nyih_nan) this.errors.nyih_nan = 'နေ့နံရွေးချယ်ရန်လိုအပ်ပါသည်။'
      if(this.phone.marital_status === '' || !this.phone.marital_status) this.errors.marital_status = "အိမ်ထောင်ရှိ/မရှိ ရွေးချယ်ရန်လိုအပ်ပါသည်။"
      if(this.phone.gender === '' || !this.phone.gender) this.errors.gender = "ကျား/မ ရွေးချယ်ရန်လိုအပ်ပါသည်။"
      if((this.phone.address_division === '' || !this.phone.address_division) || (this.phone.address_township === '' || !this.phone.address_township) || (this.phone.address === '' || !this.phone.address)) this.errors.address = "နေရပ်လိပ်စာထည့်ရန်လိုအပ်ပါသည်။"
      if((!this.call_time_day || this.call_time_day === '') || (!this.call_time_time || !this.call_time_time === '')) this.errors.time = 'ဖုန်းခေါ်ပေးစေချင်သောအချိန်ထည့်ရန်လိုအပ်ပါသည်။'
      if(!this.phone.about || this.phone.about === '') this.errors.about = "မေးမြန်းလိုသောအကြောင်းအရာထည့်ရန်လိုအပ်ပါသည်။"
      this.errors.phone_number = validatePhoneNumber(this.phone.phone_number)
    }
  },
};
</script>

<style scoped>
input[type="radio"] {
  display: inline;
  margin-top: 8px;
  margin-left: 3px;
}

/* .input-container {
  display: flex;
  border: 1px solid #000;
  background-color: #f2eff7;
  color: #3e1e68;
  border-radius: 25px;
  padding: 8px 0px 0px 10px;
} */

.md-field.md-theme-default:after {
  background-color: rgba(0, 0, 0, 0);
}

.md-button.md-theme-default {
  margin: 0px 16px 0px 0px;
}

.date__picker {
	margin-top: -8px !important;
  margin-left: 12px !important;
}
</style>
